<template>
  
</template>

<script setup>
import { watch } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const updateFavicon = (url) => {
  const isRegenere =url.toLowerCase().includes("skincare")
  let favicon = document.querySelector('link[rel="icon"]')
  
  if (!favicon) {
    favicon = document.createElement('link')
    favicon.rel = 'icon'
    document.head.appendChild(favicon)
  }
  
  // Find the matching favicon for the current path
  let newFavicon = isRegenere?'regenere.png':'favicon.svg' // Default to c.png
 
  favicon.href = newFavicon
}

// Watch for route changes
watch(
  () => route.path,
  (newPath) => {
    updateFavicon(newPath)
  },
  { immediate: true }
)
</script>

<style>

</style>