import { reactive } from 'vue'
import axios from 'axios';
import { ElMessage, ElNotification } from 'element-plus'
// import nutriprozQuestions from "./questions.json";
// import nutriprozFollowupQuestions from "./nutriproFollowupQuestion.json"
// import skincareQuestions from "./skincareQuestions.json";
// import skincareFollowupQuestions from "./skincareFollowupQuestion.json";


const prodUrl= "https://www.vibrant-america.com/lisapi/v1/lis/interactive-report-service"
const stagingUrl='https://www.vibrant-america.com/lisapi/v1/lis/interactive-report-staging-service'
const baseUrl= prodUrl

export const store = reactive({
  surveyType: "",
  questionTemplateId: null,
  barcode: "",
  dataLoaded: false,
  if_submitted: "",
  answers: {},
  questions: [],
  questionMap: {},
  user: {},

  setBarcode(barcode) {
    this.dataLoaded = false;
    this.if_submitted = ""
    this.answers = {};
    this.barcode = barcode;
    this.questions = [];
    this.questionMap = {};
  },

  async getToken(fname, lname, dob) {
    const url = baseUrl + "/auth/security/"
    const config = {
      params: { 'barcode': this.barcode, 'firstname': fname, 'lastname': lname, 'dob': dob },
    }
    return axios.get(url, config).then(response => {
      let token = response.data.token
      localStorage.setItem("jwtToken", token)
      return this.getQuestions(token)
    }).catch(err => {
      throw (err)
    })
  },

  async getQuestions(token) {
    const url = baseUrl + `/questions-data/template-questions?templateId=${this.questionTemplateId}&barcode=${this.barcode}`
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.get(url, config).then(res => {
      this.questions = res.data;
      return this.getData(token)
    }).catch(err => {
      console.log(err)
      throw (err)
    }
    )
  },

  sortQuestions(questions) {
    questions.forEach(q => {
      if (!this.questionMap[q.index]) this.questionMap[q.index] = []
      if (q.question_type === 'SingleChoice' || q.question_type === 'MultipleChoice') {
        q.options.forEach(option => {
          if (option.option_content === 'None of the above'||option.option_content === 'None') {
            q.none_id = option.option_id
          }
        })
      }
      this.questionMap[q.index].push(q)
    })
  },

  async getData(token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    const url = baseUrl + `/questions-data/getAnswer?barcodeId=${this.barcode}&templateId=${this.questionTemplateId}`
    return axios.get(url, config)
      .then(response => {
        this.dataLoaded = true;
        this.if_submitted = response.data.if_submitted === true ? true : false;
        this.user = response.data.user_info
        this.sortQuestions(this.questions)
        //console.log(this.questions)
        this.formatAnswers(response.data.answers)
        //console.log(this.answers)
        if (!this.if_submitted) {
          return this.getLastQuestion();
        } else {
          this.removeNoAnswerQuestion();
         // console.log(this.questionMap)
          return this.checkIfIsTerminated();
        }
      }).catch(error => {
        console.log(error)
        ElNotification({
          title: "Verify Failed",
          message: "There are some errors during the verification process. Please try to verify again.",
          type: "error",
          position: "top-right",
        });
        throw (error)
      })
  },

  initAnswers(questions) {
    questions?.forEach(q => {
      switch (q.question_type) {
        case 'MultipleChoice':
          this.answers[q.question_id] = { type: 'multi', option_id: [], open_answer: null }
          break;
          case 'SingleSeverityChoice':
            this.answers[q.question_id] = { type: 'SingleSeverityChoice', option_id: null, open_answer: null }
            break;
        default:
          this.answers[q.question_id] = { type: 'single', option_id: null, open_answer: null }
      }
    }
    )
  },

  setType(type) {
    this.surveyType = type;
    if (this.surveyType === 'nutripro') {
      this.questionTemplateId = 1
      //this.questions = nutriprozQuestions
    }
    if (this.surveyType === 'nutripro-followup') {
      this.questionTemplateId = 4
      //this.questions = nutriprozFollowupQuestions
    }
    if (this.surveyType === 'skincare') {
      this.questionTemplateId = 2
      // this.questions = skincareQuestions
    }
    if (this.surveyType === 'skincare-followup') {
      this.questionTemplateId = 3
      // this.questions = skincareFollowupQuestions
    }
  },

  removeNoAnswerQuestion() {
    const len = Object.keys(this.questionMap).length
    for (let i = 1; i <= len; i++) {
      const index = i.toString()
      if (this.questionMap[index]?.length > 0) {
        this.questionMap[index] = Object.values(this.questionMap[index]).filter(q => this.deleteNoAnswers(this.answers[q.question_id]))
      }
      if (this.questionMap[index]?.length === 0) delete this.questionMap[index]
    }
  },

  formatAnswers(res) {
    this.initAnswers(this.questions);
    if (res?.length) {
      res.forEach(answer => {
        const { question_id, option_id, open_answer } = answer
        if (this.answers[question_id]) {
          //render answer
          switch (this.answers[question_id].type) {
            case 'single':
              this.answers[question_id].option_id = option_id;
              this.answers[question_id].open_answer = open_answer
              break;
            case 'multi':
              this.answers[question_id].option_id.push(option_id);
              break;
          }
        }
      })
    }
  },

  // delete submitted multiple question answers that contains deleted option
  deleteNotValidMultipleAnswers(question) {
    const submittedAns = this.answers[question.question_id].option_id;
    if (submittedAns?.length > 0) {
      const options = new Set();
      question.options.forEach((option) => {
        options.add(option.option_id);
      });
      submittedAns.forEach((id) => {
        if (!options.has(id)) this.deleteOption(question.question_id, id);
      });
    }
  },

  deleteOption(question_id, option_id) {
    this.answers[question_id].option_id = this.answers[
      question_id
    ].option_id.filter((id) => id !== option_id);
  },

  getLastQuestion() {
    let lastQ = Object.keys(this.questionMap).length;
    Object.keys(this.questionMap).some((index_id,i) => {
      const unfinished = this.questionMap[index_id].some(question => {
        if (this.answers[question.question_id].type === 'multi') {
          this.deleteNotValidMultipleAnswers(question)
        }
        return !this.validateAnswers(question, this.answers[question.question_id])
      })
      if (unfinished)
         lastQ = Math.min(lastQ,Number(i))
    })
    //console.log(lastQ)
    return lastQ===Object.keys(this.questionMap).length?lastQ:lastQ+1;//if all has answer, return last one;cur in questionPanel index from 1
  },

  checkIfIsTerminated() {
    let isTerminated = false
    this.questions.forEach(q => {
      if (q.options) {
        q.options.forEach(option => {
          if (option.isTerminate && this.answers[q.question_id].option_id === option.option_id) {
            isTerminated = true;
          }
        })
      }
    })
    // this.questions.length+2:terminated page index
    return isTerminated ? this.questions.length + 2 : 1;
  },

  validateAnswers(question, answer) {//return true when it's validated
    if (question.is_optional) return true;
    if (question.dependent && this.answers[question.dependent].option_id != question.condition.toString()) return true;
    if (answer.type === "multi") return answer.option_id && answer.option_id.length > 0;
    if (question.question_type==='SingleSeverityChoice' ) return true;
    else {
      return this.checkIsNotNull(answer);
    }
  },

  deleteNoAnswers(answer) {//return true when it has submitted answer
    if (answer.type === "multi") return answer.option_id && answer.option_id.length > 0;
    else if(answer.type==='SingleSeverityChoice') return true;
    else {
      return this.checkIsNotNull(answer);
    }
  },

  checkIsNotNull(answer) {
    return answer.option_id != null || (answer.open_answer != undefined && answer.open_answer != null && answer.open_answer.toString().trim())
  },

  prepareUpload() {
    let payload = [];
    Object.keys(this.answers).forEach(question_id => {
      if (this.answers[question_id].type === 'multi') {
        this.answers[question_id].option_id.forEach(option_id => {
          payload.push({ question_id: Number(question_id), option_id: option_id })
        })
      } else if (this.answers[question_id].open_answer) {
        payload.push({ question_id: Number(question_id), open_answer: this.answers[question_id].open_answer.toString() })
      } else if (this.answers[question_id].option_id) {
        payload.push({ question_id: Number(question_id), option_id: this.answers[question_id].option_id })
      }

    })
    return payload;
  },

  async uploadData(if_submitted) {
    let payload = {
      "if_submitted": if_submitted,
      "julien_barcode": this.barcode,
      "template_id": this.questionTemplateId,
    };
    payload['answers'] = this.prepareUpload();
    const token = localStorage.getItem("jwtToken")
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.post(baseUrl + "/questions-data/postAnswer", payload, config).then(res => {
      this.if_submitted = if_submitted;
      if (!this.if_submitted) {
        ElMessage({
          message: 'Saved successfully.',
          type: 'success',
        })
        return Promise.resolve("uploaded");
      } else {
        return Promise.resolve("submitted");
      }
    }).catch(err => {
      ElMessage.error('Sorry. Something went wrong.Please try again.')
      console.log(err);
    })
  },

  ifEditable() {
    return !this.if_submitted;
  },

  async uploadFile(file) {
    const token = localStorage.getItem("jwtToken")
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data;'
      }
    }
    return axios.post(baseUrl + "/questions-data/uploadImage", file, config).then(res => {
      return res.data.imagePath
    }).catch(err => {
      const errMsg = err?.response?.data?.message ? err.response.data.message : "Sorry. Something went wrong.Please try again."
      ElMessage({
        showClose: true,
        message: errMsg,
        type: 'error',
        duration:0,
      })
      console.log(err);
    })
  },


})
